<template>
    <span>
        <StreamBarcodeReader v-show="invisible" @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
        <!-- <h2>The decoded value in QR/barcode is</h2>
        <h2>text: {{ decodedText }}</h2> -->
    </span>
</template>

<script>
import StreamBarcodeReader from '@/components/StreamBarcodeReader';
export default {
    components:{
        StreamBarcodeReader
    },
    props: {
        invisible: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            decodedText: this.value
        }
    },
    methods: {
        onDecode(text){
          console.log("text:",text)
            this.decodedText = text;
            this.$emit('update:value', this.decodedText);
            this.$emit('result_code', this.decodedText);
            this.$emit('update:invisible', false);
        },
        onLoaded() {
            console.log("loaded");
        }
    }
}
</script>

<style scoped>
a {
  color: #42b983;
}
.information {
  margin-top: 100px;
}
</style>